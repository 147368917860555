<i18n locale="th" lang="yaml" >
claim_payment.list.see_more : "@:common.see_more"
</i18n>

<template>
	<div class="claim_payment-list">
		<div class="claim_payment-list-topic">
			<div class="topic">{{topic}}</div>
			<div class="see-more">
				<router-link :to="cSeeMore">
					{{$t('claim_payment.list.see_more')}}
				</router-link>
			</div>
		</div>
		<a-list
			:loading="loading"
			item-layout="horizontal"
			class="mycard-ant-list"
			row-key="id"
			:data-source="serviceDataList">
			<template v-slot:renderItem="serviceData">
				<a-list-item>
					<ServiceLiteCard
						:key="serviceData.id"
						view-mode="claim"
						:service-data="serviceData"/>
				</a-list-item>
			</template>
		</a-list>
	</div>
</template>

<script>
import {List} from "ant-design-vue"
import ServiceLiteCard from "@components/service/ServiceLiteCard.vue"
import axios from "axios"
import ApiError from "@utils/errors/ApiError"
import LoginInfoMixin from "@mixins/LoginInfoMixin.vue"

export default {
	components : {
		ServiceLiteCard,
		"a-list" : List, "a-list-item" : List.Item,
	} ,
	mixins : [LoginInfoMixin] ,
	props : {
		topic : {
			type : String,
			default : "Claim",
		} ,
		status :{
			type : String,
			default : 'none' ,
		} ,
		maxDisplay : {
			type : Number,
			default : 5
		} ,
		maxAges : {
			type : Number,
			default : -1
		}
	} ,
	data() {
		return {
			loading : false,
			error : undefined,
			serviceDataList : [],
		}
	} ,
	computed : {
		cSeeMore() {
			return {name:"report/claim_request",query:{payment_request : this.status}}
		} ,
		sortField() {
			switch(this.status) {
				case 'request' : return 'serviceClaimPaymentRequestedDate'
				case 'complete' : return 'serviceClaimPaymentCompletedDate'
				default :  return 'serviceClaimDecisionDatetime'
			}
		}
	} ,
	mounted() {
		this.fetchData()
	} ,
	methods : {
		fetchData() {
			this.loading = true
			this.error = undefined
			const search = {
				pageSize : this.maxDisplay,
				currentPage : 1,
				sortField : this.sortField,
				sortOrder : "descend" ,
			}
			const filter = {
				serviceClaimPaymentRequest : this.status ,
				serviceCompanyId : this.currentCompany.id
			}
			if (this.maxAges > 0) {
				filter.maxAges = this.maxAges
			}


			axios.get("/api/services/search-claim-payment",{params : {search, filter}}).then((response)=>{
				this.serviceDataList = response.data.data.services
			}).catch((error) => {
				this.error = ApiError.getDisplayErrorMessage(this,error)
			}).finally(()=>{
				this.loading = false
			})
		}
	}
}
</script>

<style lang="less" scoped>
.claim_payment-list::v-deep {
	.service-card.mycard {
		padding : 8px 0 ;
		width : 100%;
		border : none;
		max-width : 485px;
	}
}
.claim_payment-list-topic {
	position : relative;
	margin-bottom : 8px;
	display: flex;
	.topic {
		font-family: @font-family-title;
		font-size : 1.2em;
		margin-right: 10px;
	}
	.see-more {
		font-size : 0.95em;
		text-decoration: underline dashed;
		align-self: center;
	}
}
.mycard-ant-list.ant-list::v-deep {
	min-height : 80px;
	.ant-empty {
		margin : 8px 0;
	}
	> .ant-spin-nested-loading {
		min-height : 80px;
	}
}
</style>
