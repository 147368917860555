<i18n locale="th" lang="yaml">
section.tickets: 'รายการ Ticket'
section.claims: 'รายการ Claim'
section.drones: 'ข้อมูล Drone'
section.tickets_stat: 'สถิติ Ticket'
section.claim_payments : 'รายการเบิกเงิน Claim ของ {name}'

ticket.new.list: 'Ticket มาใหม่/Reopen'
ticket.investigate.list: 'Ticket กำลังตรวจสอบ'
ticket.recent.list: 'Ticket ปิดล่าสุด'
ticket.recent.remark: 'แสดงเฉพาะรายการล่าสุดย้อนหลัง 5 รายการ ไม่เกิน 30 วัน'
ticket.new.remark : 'แสดงเฉพาะรายการล่าสุดย้อนหลัง 5 รายการ'

claim.prepare.list : 'รายการที่กำลังเตรียมยื่น'
claim.prepare.remark : 'แสดงรายการที่ศูนย์ได้เตรียมยื่น หรือ เตรียมยื่นใหม่ (Appeal)'
claim.wait-decision.list: 'รายการรออนุมัติ'
claim.wait-decision.remark: 'แสดงรายการที่กำลังรอทาง HGR อนุมัติ'
claim.decision.list: 'รายการที่ตอบกลับแล้ว'
claim.decision.remark: 'แสดงเฉพาะรายล่าสุด 5 รายการไม่เกิน 30 วัน'

claim_payment.none.list : 'รายการที่ยังไม่ได้เบิก'
claim_payment.none.remark : 'แสดงรายการ Claim ที่อนุมัติแล้วแต่ยังไม่ได้ยื่นเบิก'
claim_payment.request.list : 'รายการรอเงินเบิก'
claim_payment.request.remark : 'แสดงรายการ Claim ที่ยื่นเบิกกับทาง HGR แล้ว'
claim_payment.complete.list : 'รายการที่ได้รับเงินแล้ว'
claim_payment.complete.remark : 'แสดงรายการที่ได้รับเงินจากการ Claim เรียบร้อย (5 รายการล่าสุดไม่เกิน 30 วัน)'

chart.panel.service.title: 'งานซ่อมคงค้างของ {name} ทั้งหมด {amount} งาน'
service.company.chart.name: 'ใบซ่อมในแต่ละศูนย์บริการ'
service.opener.chart.name: 'ใบซ่อมรับแจ้งโดย'
service.status.chart.name: 'สถานะใบซ่อม'
service.drone.chart.name: 'Drone'

chart.panel.ticket.title: 'Ticket คงค้างของ {name} ทั้งหมด {amount} Ticket'
ticket.company.chart.name: 'Ticket ของแต่ละศูนย์บริการ'
ticket.opener.chart.name: 'Ticket รับแจ้งโดย'
ticket.status.chart.name: 'สถานะ Ticket'
ticket.drone.chart.name: 'Drone'

chart.panel.drone.title: 'Drone ของ {name} ทั้งหมด {amount} ลำ'
drone.company.chart.name: 'Drone ของแต่ละศูนย์บริการ'
drone.model.chart.name: 'Drone'
drone.firmware.chart.name: 'Firmware'
drone.status.chart.name: 'สถานะเครื่อง'
drone.lot.chart.name: 'Lot'
drone.customer.chart.name: 'เจ้าของเครื่อง'
</i18n>

<template>
	<div id="dashboard_page" class="dashboard">
		<ServiceStatistic />

		<div v-if="serviceCount > 0" class="dashboard-pane">
			<div class="page-section-title">
				{{
					$t('chart.panel.service.title', {
						name: companyName,
						amount: serviceCount,
					})
				}}
			</div>
			<a-row>
				<a-col :lg="6" :md="12">
					<ServicesChart
						:container-width="600"
						:chart-data="serviceChartData.companyChartData"
						:total-data="serviceChartData.totalCompanyData"
						:name="$t('service.company.chart.name')"
						@clicked="goToPage('/admin/service')" />
				</a-col>
				<a-col :lg="6" :md="12">
					<ServicesChart
						:container-width="600"
						:chart-data="serviceChartData.openerChartData"
						:total-data="serviceChartData.totalOpenerData"
						:name="$t('service.opener.chart.name')"
						@clicked="goToPage('/admin/service')" />
				</a-col>
				<a-col :lg="6" :md="12">
					<ServicesChart
						:container-width="600"
						:chart-data="serviceChartData.statusChartData"
						:total-data="serviceChartData.totalStatusData"
						:is-enum-required="true"
						:name="$t('service.status.chart.name')"
						@clicked="goToPage('/admin/service')" />
				</a-col>
				<a-col :lg="6" :md="12">
					<ServicesChart
						:container-width="600"
						:chart-data="serviceChartData.droneChartData"
						:total-data="serviceChartData.totalDroneData"
						:name="$t('service.drone.chart.name')"
						@clicked="goToPage('/admin/service')" />
				</a-col>
			</a-row>
		</div>

		<div v-if="ticketCount > 0" class="dashboard-pane">
			<div class="page-section-title">
				{{
					$t('chart.panel.ticket.title', {
						name: companyName,
						amount: ticketCount,
					})
				}}
			</div>
			<a-row>
				<a-col :lg="6" :md="12">
					<TicketsChart
						:container-width="600"
						:chart-data="ticketChartData.companyChartData"
						:total-data="ticketChartData.totalCompanyData"
						:name="$t('ticket.company.chart.name')"
						@clicked="goToPage('/admin/ticket')" />
				</a-col>
				<a-col :lg="6" :md="12">
					<TicketsChart
						:container-width="600"
						:chart-data="ticketChartData.openerChartData"
						:total-data="ticketChartData.totalOpenerData"
						:name="$t('ticket.opener.chart.name')"
						@clicked="goToPage('/admin/ticket')" />
				</a-col>
				<a-col :lg="6" :md="12">
					<TicketsChart
						:container-width="600"
						:chart-data="ticketChartData.statusChartData"
						:total-data="ticketChartData.totalStatusData"
						:is-enum-required="true"
						:name="$t('ticket.status.chart.name')"
						@clicked="goToPage('/admin/ticket')" />
				</a-col>
				<a-col :lg="6" :md="12">
					<TicketsChart
						:container-width="600"
						:chart-data="ticketChartData.droneChartData"
						:total-data="ticketChartData.totalDroneData"
						:name="$t('ticket.drone.chart.name')"
						@clicked="goToPage('/admin/ticket')" />
				</a-col>
			</a-row>
		</div>

		<div v-if="renderDroneChart" class="dashboard-pane">
			<div class="page-section-title">
				{{
					$t('chart.panel.drone.title', {
						name: companyName,
						amount: droneCount,
					})
				}}
			</div>
			<a-row>
				<a-col :md="6" class="chart-col">
					<DroneListPieChart
						:name="$t('drone.company.chart.name')"
						:total-data="droneChartData.totalCompanyData"
						:chart-data="droneChartData.droneCompanyChartData"
						@clicked="goToPage('/admin/drone')" />
				</a-col>
				<a-col :md="6" class="chart-col">
					<DroneListPieChart
						:name="$t('drone.model.chart.name')"
						:total-data="droneChartData.totalModelData"
						:chart-data="droneChartData.droneModelChartData"
						@clicked="goToPage('/admin/drone')" />
				</a-col>
				<a-col :md="6" class="chart-col">
					<DroneListPieChart
						:name="$t('drone.firmware.chart.name')"
						:total-data="droneChartData.totalFirmwareData"
						:chart-data="droneChartData.droneFirmwareChartData"
						@clicked="goToPage('/admin/drone')" />
				</a-col>
				<a-col :md="6" class="chart-col">
					<DroneListPieChart
						:name="$t('drone.status.chart.name')"
						:total-data="droneChartData.totalStatusData"
						:chart-data="droneChartData.droneStatusChartData"
						:is-enum-required="true"
						@clicked="goToPage('/admin/drone')" />
				</a-col>
			</a-row>
			<a-row>
				<a-col :md="12" class="chart-col">
					<DroneListBarChart
						:name="$t('drone.lot.chart.name')"
						:chart-data="droneChartData.droneLotChartData"
						:total-data="droneChartData.totalLotData"
						@clicked="goToPage('/admin/drone')" />
				</a-col>
				<a-col :md="12" class="chart-col">
					<DroneListBarChart
						:name="$t('drone.customer.chart.name')"
						:chart-data="droneChartData.droneCustomerChartData"
						:total-data="droneChartData.totalCustomerData"
						@clicked="goToPage('/admin/drone')" />
				</a-col>
			</a-row>
		</div>

		<ServiceBoard />

		<div class="dashboard-pane claim-pane">
			<div class="page-section-title">
				{{ $t('section.claims') }}
			</div>

			<div class="dashboard-data-pane">
				<div class="claim-data-list">
					<ServiceClaimList :topic="$t('claim.prepare.list')" :show-only-my-company="true" mode="prepare" />
					<div class="data-remark">
						{{ $t('claim.prepare.remark') }}
					</div>
				</div>
				<div class="claim-data-list">
					<ServiceClaimList :topic="$t('claim.wait-decision.list')" :show-only-my-company="false" mode="wait-decision" />
					<div class="data-remark">
						{{ $t('claim.wait-decision.remark') }}
					</div>
				</div>
				<div class="claim-data-list">
					<ServiceClaimList
						:topic="$t('claim.decision.list')"
						:show-only-my-company="false"
						mode="decision"
						:max-ages="30"
						:max-display="5" />
					<div class="data-remark">
						{{ $t('claim.decision.remark') }}
					</div>
				</div>
			</div>
		</div>
		<div class="dashboard-pane claim-payment-pane">
			<div class="page-section-title">
				{{ $t('section.claim_payments', { name: companyName }) }}
			</div>

			<div class="dashboard-data-pane">
				<div class="claim-data-list">
					<ServiceClaimPaymentList :topic="$t('claim_payment.none.list')" status="none" />
					<div class="data-remark">
						{{ $t('claim_payment.none.remark') }}
					</div>
				</div>
				<div class="claim-data-list">
					<ServiceClaimPaymentList :topic="$t('claim_payment.request.list')" status="request" />
					<div class="data-remark">
						{{ $t('claim_payment.request.remark') }}
					</div>
				</div>
				<div class="claim-data-list">
					<ServiceClaimPaymentList :topic="$t('claim_payment.complete.list')" status="complete" :max-ages="30" :max-display="5" />
					<div class="data-remark">
						{{ $t('claim_payment.complete.remark') }}
					</div>
				</div>
			</div>
		</div>


		<div v-if="isManangement" class="dashboard-pane ticket-pane">
			<div class="page-section-title">
				{{ $t('section.tickets') }}
			</div>

			<div class="dashboard-data-pane">
				<div class="ticket-data-list">
					<TicketLiteList :topic="$t('ticket.new.list')" see-more-status="new,reopen" :status-list="['new', 'reopen']" />
				</div>
				<div class="ticket-data-list">
					<TicketLiteList :topic="$t('ticket.investigate.list')" see-more-status="investigate" :status-list="['investigate']" />
				</div>
				<div class="ticket-data-list">
					<TicketLiteList
						:topic="$t('ticket.recent.list')"
						see-more-status="close,pending,resolved"
						:max-ages="30"
						:status-list="['close', 'pending', 'resolved']" />
					<div class="data-remark">
						{{ $t('ticket.recent.remark') }}
					</div>
				</div>
			</div>
		</div>


		<div v-else class="dashboard-pane ticket-pane">
			<div class="page-section-title">
				{{ $t('section.tickets', { name: companyName }) }}
			</div>

			<div class="dashboard-data-pane">
				<div class="ticket-data-list">
					<TicketLiteList
						:topic="$t('ticket.new.list')"
						:show-only-my-company="true"
						see-more-status="new,investigate,reopen"
						:status-list="['new', 'investigate', 'reopen']"/>
					<div class="data-remark">
						{{ $t('ticket.new.remark') }}
					</div>
				</div>
				<div class="ticket-data-list">
					<TicketLiteList
						:topic="$t('ticket.recent.list')"
						:show-only-my-company="true"
						see-more-status="close,pending,resolved"
						:max-ages="30"
						:status-list="['close', 'pending', 'resolved']"/>
					<div class="data-remark">
						{{ $t('ticket.recent.remark') }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
// TODO CLAIMTODO
import ServiceStatistic from '@components/dashboard/ServiceStatistic.vue'
import ServiceBoard from '@components/dashboard/ServiceBoard.vue'
import ServiceClaimList from '@components/service/ServiceClaimList.vue'
import TicketLiteList from '@components/ticket/TicketLiteList.vue'
import ApiError from '@utils/errors/ApiError'
import axios from 'axios'
import { Row, Col } from 'ant-design-vue'
import ServicesChart from '../chart/ServicesChart.vue'
import TicketsChart from '../chart/TicketsChart.vue'
import LoginInfoMixinVue from '@/src/mixins/LoginInfoMixin.vue'
import DroneListPieChart from '../chart/DroneListPieChart.vue'
import DroneListBarChart from '../chart/DroneListBarChart.vue'
import ServiceClaimPaymentList from '../service/ServiceClaimPaymentList.vue'

export default {
	components: {
    ServiceBoard,
    TicketLiteList,
    ServiceStatistic,
    ServiceClaimList,
    "a-row": Row,
    "a-col": Col,
    ServicesChart,
    TicketsChart,
    DroneListPieChart,
    DroneListBarChart,
    ServiceClaimPaymentList
},
	mixins: [LoginInfoMixinVue],
	props: {
		isManangement: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			serviceChartData: {
				companyChartData: {},
				totalCompanyData: 0,
				openerChartData: {},
				totalOpenerData: 0,
				statusChartData: {},
				totalStatusData: 0,
				droneChartData: {},
				totalDroneData: 0,
			},
			ticketChartData: {
				companyChartData: {},
				totalCompanyData: 0,
				openerChartData: {},
				totalOpenerData: 0,
				statusChartData: {},
				totalStatusData: 0,
				droneChartData: {},
				totalDroneData: 0,
			},
			droneChartData: {
				droneModelChartData: {},
				totalModelData: 0,
				droneFirmwareChartData: {},
				totalFirmwareData: 0,
				droneStatusChartData: {},
				totalStatusData: 0,
				droneLotChartData: {},
				totalLotData: 0,
				droneCustomerChartData: {},
				totalCustomerData: 0,
				droneCompanyChartData: {},
				totalCompanyData: 0,
			},
		}
	},
	computed: {
		serviceCount() {
			return (
				this.serviceChartData.totalCompanyData ||
				this.serviceChartData.totalOpenerData ||
				this.serviceChartData.totalStatusData ||
				this.serviceChartData.totalDroneData
			)
		},
		ticketCount() {
			return (
				this.ticketChartData.totalCompanyData ||
				this.ticketChartData.totalOpenerData ||
				this.ticketChartData.totalStatusData ||
				this.ticketChartData.totalDroneData
			)
		},
		renderDroneChart() {
			return (
				(this.droneChartData.totalLotData ||
					this.droneChartData.totalCustomerData ||
					this.droneChartData.totalModelData ||
					this.droneChartData.totalFirmwareData ||
					this.droneChartData.totalStatusData ||
					this.droneChartData.totalCompanyData) > 0
			)
		},
		droneCount() {
			return this.droneChartData.totalModelData || this.droneChartData.totalFirmwareData || this.droneChartData.totalStatusData
		},
		canCreateTicket() {
			return this.$authorize('create', 'ticket')
		},
		companyName() {
			return this.currentCompany.name
		},
	},
	mounted() {
		this.fetchServiceChartData()
		this.fetchTicketChartData()
		this.fetchDroneChartData()
	},
	methods: {
		fetchServiceChartData() {
			axios
				.get('/api/services/stat-active-services-by-company')
				.then((response) => {
					this.serviceChartData.companyChartData = response.data.data.dataset
					this.serviceChartData.totalCompanyData = response.data.data.total
				})
				.catch((error) => {
					this.error = ApiError.getDisplayErrorMessage(this, error)
				})
			axios
				.get('/api/services/stat-active-services-by-opener')
				.then((response) => {
					this.serviceChartData.openerChartData = response.data.data.dataset
					this.serviceChartData.totalOpenerData = response.data.data.total
				})
				.catch((error) => {
					this.error = ApiError.getDisplayErrorMessage(this, error)
				})
			axios
				.get('/api/services/stat-active-services-by-status')
				.then((response) => {
					this.serviceChartData.statusChartData = response.data.data.dataset
					this.serviceChartData.totalStatusData = response.data.data.total
				})
				.catch((error) => {
					this.error = ApiError.getDisplayErrorMessage(this, error)
				})
			axios
				.get('/api/services/stat-active-services-by-drone-model-display-name')
				.then((response) => {
					const data = response.data.data
					const chartData = {}
					for (const dataset of data.dataset) {
						chartData[dataset.name] = dataset.numServices
					}
					this.serviceChartData.droneChartData = chartData
					this.serviceChartData.totalDroneData = data.total
				})
				.catch((error) => {
					this.serviceChartData.error = ApiError.getDisplayErrorMessage(this, error)
				})
		},
		fetchTicketChartData() {
			axios
				.get('/api/tickets/get-ticket-data-by-company')
				.then((response) => {
					this.ticketChartData.companyChartData = response.data.data.dataset
					this.ticketChartData.totalCompanyData = response.data.data.total
				})
				.catch((error) => {
					this.error = ApiError.getDisplayErrorMessage(this, error)
				})
			axios
				.get('/api/tickets/get-ticket-data-by-opener')
				.then((response) => {
					this.ticketChartData.openerChartData = response.data.data.dataset
					this.ticketChartData.totalOpenerData = response.data.data.total
				})
				.catch((error) => {
					this.error = ApiError.getDisplayErrorMessage(this, error)
				})
			axios
				.get('/api/tickets/get-ticket-data-by-status')
				.then((response) => {
					this.ticketChartData.statusChartData = response.data.data.dataset
					this.ticketChartData.totalStatusData = response.data.data.total
				})
				.catch((error) => {
					this.error = ApiError.getDisplayErrorMessage(this, error)
				})
			axios
				.get('/api/tickets/get-ticket-data-by-drone-model-display-name')
				.then((response) => {
					const data = response.data.data
					const chartData = {}
					for (const dataset of data.dataset) {
						chartData[dataset.name] = dataset.numTickets
					}
					this.ticketChartData.droneChartData = chartData
					this.ticketChartData.totalDroneData = data.total
				})
				.catch((error) => {
					this.error = ApiError.getDisplayErrorMessage(this, error)
				})
		},
		fetchDroneChartData() {
			axios
				.get('/api/drones/stat-drones-per-model')
				.then((response) => {
					const data = response.data.data
					const chartData = {}
					for (const dataset of data.dataset) {
						chartData[dataset.name] = dataset.numDrones
					}
					this.droneChartData.droneModelChartData = chartData
					this.droneChartData.totalModelData = data.total
				})
				.catch((error) => {
					this.error = ApiError.getDisplayErrorMessage(this, error)
				})

			axios
				.get('/api/drones/stat-drones-per-firmware')
				.then((response) => {
					const data = response.data.data
					const chartData = {}
					for (const dataset of data.dataset) {
						chartData[dataset.name] = dataset.numDrones
					}
					this.droneChartData.droneFirmwareChartData = chartData
					this.droneChartData.totalFirmwareData = data.total
				})
				.catch((error) => {
					this.error = ApiError.getDisplayErrorMessage(this, error)
				})

			axios
				.get('/api/drones/stat-drones-per-status')
				.then((response) => {
					const data = response.data.data
					const chartData = {}
					for (const dataset of data.dataset) {
						chartData[dataset.name] = dataset.numDrones
					}
					this.droneChartData.droneStatusChartData = chartData
					this.droneChartData.totalStatusData = data.total
				})
				.catch((error) => {
					this.error = ApiError.getDisplayErrorMessage(this, error)
				})
			axios
				.get('/api/drones/stat-drones-per-lot')
				.then((response) => {
					const data = response.data.data
					const chartData = {
						labels: [],
						datasets:  [
							{
								label: 'number of drone',
								backgroundColor: '#ff6d22',
								data: [],
							},
						],
					}
					for (const dataset of data.dataset) {
						chartData.labels.push(dataset.name)
						chartData.datasets[0].data.push(dataset.numDrones)
					}
					this.droneChartData.droneLotChartData = chartData
					this.droneChartData.totalLotData = data.total
				})
				.catch((error) => {
					this.error = ApiError.getDisplayErrorMessage(this, error)
				})
			axios
				.get('/api/drones/stat-drones-per-customer')
				.then((response) => {
					const data = response.data.data
					const datasets = data.dataset
					const chartData = {
						labels: [],
						datasets:  [
							{
								label: 'number of drone',
								backgroundColor: '#f7e013',
								data: [],
							},
						],
					}
					for (const dataset of datasets) {
						chartData.labels.push(dataset.name)
						chartData.datasets[0].data.push(dataset.numDrones)
					}
					this.droneChartData.droneCustomerChartData = chartData
					this.droneChartData.totalCustomerData = data.total
				})
				.catch((error) => {
					this.error = ApiError.getDisplayErrorMessage(this, error)
				})
			axios
				.get('/api/drones/stat-drones-per-company')
				.then((response) => {
					const data = response.data.data
					const chartData = {}
					for (const dataset of data.dataset) {
						chartData[dataset.name] = dataset.numDrones
					}
					this.droneChartData.droneCompanyChartData = chartData
					this.droneChartData.totalCompanyData = data.total
				})
				.catch((error) => {
					this.error = ApiError.getDisplayErrorMessage(this, error)
				})
		},
		goToPage(path) {
			this.$router.push(path)
		},
	},
}
</script>
<style lang="less" scope>
@import './dashboard.less';
.dashboard {
	.ticket-data-list {
		flex-grow: 50%;
		width: 50%;
	}

	.claim-data-list {
		flex-grow: 50%;
		width: 50%;
		.data-remark {
			text-align: left;
		}
	}
}
</style>
