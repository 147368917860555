<i18n locale="th" lang="yaml" >
ticket.list.see_more : "@:common.see_more"
</i18n>

<template>
	<div class="ticket-lite-list">
		<div class="ticket-lite-topic">
			<div class="topic">{{topic}}</div>
			<div class="see-more">
				<router-link :to="cSeeMore">
					{{$t('ticket.list.see_more')}}
				</router-link>

			</div>
		</div>
		<a-list
			:loading="loading"
			item-layout="horizontal"
			class="mycard-ant-list"
			row-key="id"
			:data-source="ticketDataList">
			<template v-slot:renderItem="ticketData">
				<a-list-item>
					<TicketLiteListCard
						:hide-company="showOnlyMyCompany"
						:ticket-data="ticketData"/>
				</a-list-item>
			</template>
		</a-list>
	</div>
</template>

<script>
import {List} from "ant-design-vue"
import TicketLiteListCard from "./TicketLiteListCard.vue"
import axios from "axios"
import ApiError from "@utils/errors/ApiError"
import LoginInfoMixin from "@mixins/LoginInfoMixin.vue"

export default {
	components : {
		TicketLiteListCard ,
		"a-list" : List, "a-list-item" : List.Item,
	} ,
	mixins : [LoginInfoMixin] ,
	props : {
		topic : {
			type : String,
			default : "Ticket",
		} ,
		statusList : {
			type : Array,
			default : () => []
		} ,
		maxDisplay : {
			type : Number,
			default : 5
		} ,
		seeMoreStatus : {
			type : String,
			default : undefined
		} ,
		showOnlyMyCompany : {
			type : Boolean,
			default : false
		} ,
		maxAges : {
			type : Number,
			default : -1
		}
	} ,
	data() {
		return {
			loading : false,
			error : undefined,
			ticketDataList : []
		}
	} ,
	computed : {
		cSeeMore() {
			if (this.$notEmpty(this.seeMoreStatus))
				return {name:"ticket/list",query:{status_list : this.seeMoreStatus}}
			else
				return {name:"ticket/list"}
		}
	} ,
	watch : {
		showOnlyMyCompany() {
			this.fetchData()
		}
	} ,
	mounted() {
		this.fetchData()
	} ,
	methods : {
		fetchData() {
			this.loading = true
			this.error = undefined
			const search = {
				pageSize : this.maxDisplay,
				currentPage : 1,
				sortField : 'updatedTime' ,
				sortOrder : "descend" ,
			}
			const filter = {
				statusList : this.statusList,
				...(this.maxAges > 0 && {
					maxAges: this.maxAges,
				})
			}
			if (this.showOnlyMyCompany) {
				filter.reportByCompanyId = this.currentCompany.id
			}
			axios.get("/api/tickets/search",{params : {search,filter}}).then((response)=>{
				this.ticketDataList = response.data.data.tickets
			}).catch((error) => {
				this.error = ApiError.getDisplayErrorMessage(this,error)
			}).finally(()=>{
				this.loading = false
			})
		}
	}
}
</script>
<style lang="less" scoped>
.ticket-lite-list {

}
.ticket-lite-topic {
	position : relative;
	margin-bottom : 8px;
	display: flex;
	.topic {
		font-family: @font-family-title;
		font-size : 1.2em;
		margin-right: 10px;

	}
	.see-more {
		align-self: center;
		font-size : 0.95em;
		text-decoration: underline dashed;
	}
}
.mycard-ant-list.ant-list::v-deep {
	min-height : 80px;
	.ant-empty {
		margin : 8px 0;
	}
	> .ant-spin-nested-loading {
		min-height : 80px;
	}
}
</style>
